<template>
  <div id="data-list-list-view">
    <vx-card title="search" class="mb-2">
      <div class="vx-row">
        <!-- StartDate -->
        <div class="vx-col lg:w-1/4">
          <label class="vs-input--label">Start Date </label>
          <datepicker
            :format="format"
            placeholder="Start Date"
            v-model="medicalPackageSearchOb.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <!-- EndDate -->
        <div class="vx-col lg:w-1/4">
          <label class="vs-input--label">End Date </label>
          <datepicker
            :format="format"
            placeholder="End Date"
            v-model="medicalPackageSearchOb.DateTo"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-col lg:w-1/4">
          <label class="vs-input--label"
          >{{ $t("Medical Package Categorys") }}
        </label>
        <v-select
          label="NameEN"
          class="w-full"
          v-validate="'required'"
          v-model="medicalPackageSearchOb.MedicalPackageCategorID"
          :options="MedicalPackageCategories"
          :reduce="(ID) => ID.ID"
        />
        </div>
        <div class="vx-col lge:w-1/5">
         <p class="vs-input--label">{{ $t("Status") }}</p>
            <vs-radio v-model="medicalPackageSearchOb.IsActive" vs-name="Status" :vs-value="true">{{
              $t("activated")
            }}</vs-radio>

            <vs-radio  class="ml-2"  v-model="medicalPackageSearchOb.IsActive" vs-name="Status" :vs-value="false">{{
              $t("deactivated")
            }}</vs-radio>
        </div>
        <div class="vx-col lge:w-1/4 mt-2">
      <p class=" xl:w-3/12 text-left m-2">{{ $t("Published") }}</p>
            <vs-radio v-model="medicalPackageSearchOb.IsPublished" vs-name="Published" :vs-value="true">{{
              $t("Yes")
            }}</vs-radio>

            <vs-radio class="ml-2" v-model="medicalPackageSearchOb.IsPublished" vs-name="Published" :vs-value="false">{{
              $t("no")
            }}</vs-radio>
        </div>
        <!-- Keyword -->
        <vs-input
          class="w-full mt-3 vx-col lg:w-1/4"
          label="Keyword"
          v-model="medicalPackageSearchOb.Keyword"
        />
            <vs-button
          color="primary"
          type="filled"
        class=" ml-4 mt-8"
          @click="medicalPackageSearch(medicalPackageSearchOb)"
          >Search</vs-button
        >
       <vs-button

             class=" ml-4 mt-8"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
      </div>
      <div class="vx-row  justify-end">

      </div>
    </vx-card>

    <vs-table
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="medicalPackages"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNewData"
          >{{ $t("AddNew") }}</vs-button
        >

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                medicalPackages.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : medicalPackages.length
              }}
              of {{ medicalPackages.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Name en">{{ $t("NameEn") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("NameAr") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("Category") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("Active Form") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("Active To") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("Published") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("Hospital") }}</vs-th>
        <vs-th sort-key="Name">{{ $t("Status") }}</vs-th>
        <vs-th>{{ $t("Action") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.NameEN }}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.NameAR }}</p>
            </vs-td>
            <vs-td>
              <p
                class="product-name font-medium truncate"
                v-if="tr.MedicalPackageCategory"
              >
                {{ tr.MedicalPackageCategory.NameEN }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.StartDate }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.EndDate }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.IsPublished }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate" v-if="tr.Hospital">
                {{ tr.Hospital.NameEN }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ new Date(tr.EndDate) > new Date() }}
              </p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <vx-tooltip
                :text="$t('Publish')"
                position="top"
                style="display: inline"
              >
                <feather-icon
                  v-if="!tr.IsPublished"
                  icon="NavigationIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click="openConfirm2(tr)"
                />
              </vx-tooltip>
              <feather-icon
                class="ml-2"
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click="openConfirm(tr)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleMedicalPackageCategory from "@/store/settings/MedicalPackageCategory/moduleMedicalPackageCategory.js";

import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      itemsPerPage: 10,
      selected: [],
      search: {},
      format: "dd-MM-yyyy",
      rowPublished: {},
    };
  },
  methods: {
    medicalPackageSearch(medicalPackageSearchOb) {
      debugger;
      this.$store.dispatch(
        "MedicalPackageList/SearchMedicalPackage",
        medicalPackageSearchOb
      );
    },
    addNewData() {
      this.$router.push("MedicalPackageAddEdit");
    },
    editData(tr) {
      this.$router.push("MedicalPackageAddEdit/" + tr.ID);
    },
    deleteData(data) {
      this.$store
        .dispatch("MedicalPackageList/DeleteMedicalPackage", data)
        .catch((err) => {
          console.error(err);
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("Delete Confirm"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert,
      });
    },
    resetData(){
 this.$store.state.MedicalPackageList.medicalPackageSearchObj={};
    var object = {
      DateFrom: null,
      DateTo: null,
      Keyword: "",
      IsActive:null,
      ValidDate: new Date(),

    };
     this.medicalPackageSearch(object);
 },
    openConfirm2(data) {
      this.rowPublished = data;
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Confirm`,
        text: this.$t("Do You Want Publish This Package"),
        acceptText: this.$t("Publish"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert2,
      });
    },
    changePublish(tr) {
      this.$store
        .dispatch("MedicalPackageList/SetPublishMedicalPackage", tr)
        .catch((err) => {
          console.error(err);
        });
    },
    acceptAlert2() {
      this.changePublish(this.rowPublished);
      this.$vs
        .notify({
          color: "success",
          title: "Publish record",
          text: this.$t("PublishS uccessfully"),
        })
        .then((x) => {
          this.medicalPackageSearch({
            ValidDate: new Date(),
          });
        });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("Deleted Successfully"),
      });
    },
  },
  computed: {
    medicalPackageSearchOb() {
      debugger;
      return this.$store.state.MedicalPackageList.medicalPackageSearchObj;
    },
      MedicalPackageCategories() {
      return this.$store.state.MedicalPackageCategoryList.MedicalPackageCategorys;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    medicalPackages() {
      return this.$store.state.MedicalPackageList.medicalPackages;
    },
  },
  created() {
    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }
        if (!moduleMedicalPackageCategory.isRegistered) {
      this.$store.registerModule(
        "MedicalPackageCategoryList",
        moduleMedicalPackageCategory
      );
      moduleMedicalPackageCategory.isRegistered = true;
    }
    var object = {
      DateFrom: null,
      DateTo: null,
      Keyword: "",
      IsActive:null,
      ValidDate: new Date(),

    };
       this.$store.dispatch(
      "MedicalPackageCategoryList/GetAllMedicalPackageCategorys"
    );
this.resetData();
  },
};
</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
